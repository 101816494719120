<template>
  <div class="about">
    <div class="inner-banner">
      <div class="overlay">
	<div class="container clearfix">
	  <h2>Contact</h2>
	  <ul>
	    <li>
	      <router-link to="/" class="nav-link"> Home  </router-link>
	    </li>
	    <li>/</li>
	    <li>Contact us</li>
	  </ul>
	</div> <!-- /.container -->
      </div> <!-- /.overlay -->
    </div> <!-- /.inner-banner -->

    <div class="contact-us">
      <div class="container" v-if="gettingInput">
	<div class="row">
	  <div class="col-lg-7 col-12">
	    <div class="contact-us-form">
	      <form  id="contactForm"
		     @submit.prevent="sendEmail" class="contact-form form-styl-two">	      
		<div class="row">
		  <div class="col-sm-6 col-12">
		    <input type="text" required placeholder="Your Name*"
			   id="name"
			   v-model="data.name">
		  </div>
		  <div class="col-sm-6 col-12">
		    <input type="email" required placeholder="Email Address*" 
			   id="email"
			   v-model="data.email">
		  </div>
		</div>
		<input type="text" placeholder="Phone"
		       id="phone"
		       v-model="data.phone">
		<textarea placeholder="Your Message" i
			  d="message"
			  v-model="data.message">
		</textarea>
		<input type="submit" d	id="submit" value="Send Message">
	      </form>
	    </div> <!-- /.contact-us-form -->
	  </div> <!-- /.col- -->
	  <div class="col-lg-5 col-12">
	    <div class="contact-address">
	    </div> <!-- /.contact-address -->
	  </div> <!-- /.col- -->
	</div> <!-- /.row -->
      </div> <!-- /.container -->

      <!--Contact Form Validation Markup -->
      <!-- Contact alert -->
      <div v-else class="alert-wrapper" id="alert-success">
	<div id="success" v-if='success'>
	  <div class="wrapper">
	    <h2>Thanks for contacting us. <br> We will be in touch.</h2>
	  </div>
	</div>
      </div> <!-- End of .alert_wrapper -->
      <div class="alert-wrapper" id="alert-error" v-else>
	<div id="error">
	  <div class="wrapper">
	    <h2>Sorry! Something went wrong.</h2>
	  </div>
	</div>
      </div> <!-- End of .alert_wrapper -->
    </div> <!-- /.contact-us -->
  </div>
</template>

<script>
import emailjs from 'emailjs-com';

var templateID='template_1xg64at';
var serviceID = 'service_s10rfcw';
var userID = 'user_s7hOeL0CUUH0Tf0wJlTaT';
emailjs.init(userID);

export default {
    title: 'Contact - Ten Factor Growth',
    data() {
	return{
	    gettingInput: true,
	    success: false,
	    data: {
		name: null,
		email: null,
		phone: null,
		message: null
	    }
	}
    },
    created() {
	this.gettingInput = true;
	this.success = false;
    },
    methods: {
	sendEmail: function(e) {
	    console.log('sendEmail');
	    console.log(this.data);
	    this.gettingInput=false;
	    this.success=true;

	    emailjs.send(serviceID, templateID, this.data, userID)
		.then(function(response) {
		    console.log('SUCCESS!', response.status, response.text);
		    this.success=true;
		}, function(error) {
		    console.log('FAILED...', error);
		    this.success=false;
		})
	}
    }
}
</script>

<style scoped>
.alert-wrapper {
  position: block;
  width: 100%;
  height:100%;
  top: 0;
  left: 0;
  z-index: 9999999;
}
#success,#error {
  position: relative;
  width:500px;
  height: 100px;
  top: calc(50% - 50px);
  left:calc(50% - 250px);
  text-align: center;
}

#success .wrapper,#error .wrapper {
  display: table;
  width:100%;
  height: 100%;
}
#success .wrapper p,#error .wrapper p{
  display: table-cell;
  vertical-align: middle;
  letter-spacing: 1px;
}
#success p
{
    color: black;
}
#error p {
    color: #C9182B;}

#error button, #success button {
  color:#fff;
  width:40px;
  height:40px;
  line-height: 20px;
  background: transparent;
  border-radius: 50%;
  position: absolute;
  top: -20px;
  right: -20px;
  font-size: 20px;
}

</style>
